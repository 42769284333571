import { useState, useEffect } from "react";
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useCall, useEthers } from '@usedapp/core';
import BigNumber from 'bignumber.js';

import { networkToTokenTransmuterContract } from '../utils/constants';

import TokenTransmuterABI from '../utils/abis/TokenTransmuterABI.json';

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useTokenTransmuterVesterMetadata = () => {
  const { account, chainId } = useEthers()

  const [transmuterContractAddress, setTransmuterContractAddress] = useState<undefined | string>();
  const linearVestingDuration = 60 * 60 * 24 * 365;

  useEffect(() => {
    let mounted = true;
    const constructContract = () => {
      if(chainId) {
        const contractAddress = networkToTokenTransmuterContract[chainId];
        if(mounted) {
          setTransmuterContractAddress(contractAddress);
        }
      }
    }
    constructContract();
    return () => {
      mounted = false
    };
  }, [chainId]);

  const transmuterInterface = new utils.Interface(TokenTransmuterABI)

  const { value: addressVestingCode, error: errorAddressVestingCode } = useCall(transmuterContractAddress && chainId && account && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'addressToVestingCode',
    args: [account]
  }) ?? {}

  const { value: addressToVestingStartTime, error: errorAddressToVestingStartTime } = useCall(transmuterContractAddress && chainId && account && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'addressToVestingStartTime',
    args: [account]
  }) ?? {}

  const { value: addressToTotalAllocatedOutputToken, error: errorAddressToTotalAllocatedOutputToken } = useCall(transmuterContractAddress && chainId && account && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'addressToTotalAllocatedOutputToken',
    args: [account]
  }) ?? {}
  
  const { value: addressToTotalReleasedOutputToken, error: errorAddressToTotalReleasedOutputToken } = useCall(transmuterContractAddress && chainId && account && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'addressToTotalReleasedOutputToken',
    args: [account]
  }) ?? {}

  const { value: vestedAmount, error: errorVestedAmount } = useCall(transmuterContractAddress && chainId && account && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'vestedAmount',
    args: [account]
  }) ?? {}

  if(!account) {
    return {
      readSuccess: false
    }
  }
  if(errorAddressVestingCode || errorAddressToVestingStartTime || errorAddressToTotalAllocatedOutputToken || errorAddressToTotalReleasedOutputToken || errorVestedAmount) {
    console.error(`errorAddressVestingCode: ${errorAddressVestingCode}`);
    console.error(`errorAddressToVestingStartTime: ${errorAddressToVestingStartTime}`);
    console.error(`errorAddressToTotalAllocatedOutputToken: ${errorAddressToTotalAllocatedOutputToken}`);
    console.error(`errorAddressToTotalReleasedOutputToken: ${errorAddressToTotalReleasedOutputToken}`);
    console.error(`errorVestedAmount: ${errorVestedAmount}`);
    return {
      readSuccess: false
    }
  }
  console.log({vestedAmount,  addressToTotalReleasedOutputToken})
  return {
    addressVestingCode: addressVestingCode?.[0],
    addressToVestingStartTime: addressToVestingStartTime?.[0] ? Number(addressToVestingStartTime?.[0]) : 0,
    addressToVestingEndTime: addressToVestingStartTime?.[0] && (Number(addressToVestingStartTime?.[0]) > 0) ? Number(addressToVestingStartTime?.[0]) + linearVestingDuration : 0,
    addressToTotalAllocatedOutputToken: addressToTotalAllocatedOutputToken?.[0] ? new BigNumber(addressToTotalAllocatedOutputToken?.[0].toString()).toString() : 0,
    addressToTotalReleasedOutputToken: addressToTotalReleasedOutputToken?.[0] ? new BigNumber(addressToTotalReleasedOutputToken?.[0].toString()).toString() : 0,
    addressToClaimable: vestedAmount?.[0] && addressToTotalReleasedOutputToken ? new BigNumber(vestedAmount?.[0].toString()).minus(new BigNumber(addressToTotalReleasedOutputToken?.[0].toString())).toString() : 0,
    readSuccess: (!errorAddressVestingCode && !errorAddressToVestingStartTime && !errorAddressToTotalAllocatedOutputToken && !errorAddressToTotalReleasedOutputToken && !errorVestedAmount) ? true : false
  }
}

export default useTokenTransmuterVesterMetadata;