import React, {useState, useEffect, Fragment} from 'react';
import { withRouter, RouteComponentProps } from "react-router";

import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TwitterIcon from '@mui/icons-material/Twitter';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import MapIcon from '@mui/icons-material/Map';
import ExampleIcon from '@mui/icons-material/Favorite';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import TelegramIcon from '@mui/icons-material/Telegram';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PaletteIcon from '@mui/icons-material/Palette';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InstagramIcon from '@mui/icons-material/Instagram';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DiscordIcon from '../assets/svg/discord.svg'

import { PropsFromRedux } from '../containers/NavigationLeftSideBarContainer';

const navigationMenu = [
  {
    text: 'Swap',
    path: '/',
    icon: <AutoAwesomeIcon />
  },
  {
    text: 'Curation',
    path: 'https://seen.haus/curate/self-minting',
    icon: <TravelExploreIcon />,
    external: true,
  },
  {
    text: 'Creation',
    path: 'https://seen.haus/create',
    icon: <PaletteIcon />,
    external: true,
  },
  {
    text: 'Governance',
    path: 'https://snapshot.org/#/seenhaus.eth',
    icon: <AssuredWorkloadIcon />,
    external: true,
  },
  {
    text: 'Drops',
    path: 'https://seen.haus/drops',
    icon: <LocalFireDepartmentIcon />,
    external: true,
  },
  {
    text: 'Creators',
    path: 'https://seen.haus/creators',
    icon: <Diversity2Icon />,
    external: true,
  },
  {
    text: 'Roadmap',
    path: 'https://seen.haus/roadmap',
    icon: <MapIcon />,
    external: true,
  },
  {
    text: 'Social',
    icon: <ConnectWithoutContactIcon />,
    children: [
      {
        text: 'Twitter',
        path: 'https://twitter.com/seen_haus',
        icon: <TwitterIcon />,
        external: true,
      },
      {
        text: 'Discord',
        path: 'https://discord.com/invite/dad8J4f',
        icon: DiscordIcon,
        nonMuiIcon: true,
        external: true,
      },
      {
        text: 'Telegram',
        path: 'https://t.me/seenhaus',
        icon: <TelegramIcon />,
        external: true,
      },
      {
        text: 'Instagram',
        path: 'https://www.instagram.com/seen.haus/',
        icon: <InstagramIcon />,
        external: true,
      },
    ]
  },
];

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type PropsWithRouter = RouteComponentProps;

function NavigationLeftSideBarDesktop(props: PropsWithRouter) {
  const classes = useStyles();

  const [openCollapseSections, setOpenCollapseSections] = useState<Number[]>([]);

  const [localShowLeftMenu, setLocalShowLeftMenu] = useState(false)

  const openExternalLink = (path: string) => {
    if(window) {
      //@ts-ignore
      window.open(path, '_blank').focus();
    }
  }

  const toggleOpenCollapseState = (navigationMenuIndex: number) => {
    // Check if the navigationMenuIndex is currently in openCollapseSections
    let indexInCurrentState = openCollapseSections.indexOf(navigationMenuIndex)
    if(indexInCurrentState === -1) {
      // Currently not open
      // We add this index to the list of open collapsable areas to open it
      let newOpenCollapseSections = [...openCollapseSections, navigationMenuIndex];
      setOpenCollapseSections(newOpenCollapseSections);
    } else {
      // Currently open
      // We remove this index from the list of open collapsable areas to close it
      let newOpenCollapseSections = [...openCollapseSections].filter(item => item !== navigationMenuIndex);
      setOpenCollapseSections(newOpenCollapseSections);
    }
  }

  return (
    <div>
        <React.Fragment key={'left'}>
            <Drawer
              anchor={'left'}
              variant="persistent"
              open={true}
              sx={{
                width: 250,
                position: 'relative',
                '& .MuiDrawer-paper': {
                  zIndex: 1,
                  top: 58,
                },
              }}
            >
                <div
                    className={classes.list}
                    role="presentation"
                >
                  <List>
                      {navigationMenu.map((item, index) => 
                          <Fragment key={`parent-${index}`}>
                            <ListItem 
                              onClick={() => {
                                if(item.path) {
                                  if(!item.external) {
                                    props.history.push(item.path)
                                  } else {
                                    openExternalLink(item.path)
                                  }
                                } else if (item.children) {
                                  toggleOpenCollapseState(index)
                                }
                              }}
                              button
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                                {item.children &&
                                  <>
                                    {openCollapseSections.indexOf(index) > -1 ? <ExpandLess /> : <ExpandMore />}
                                  </>
                                }
                            </ListItem>
                            {item.children &&
                              <Collapse in={openCollapseSections.indexOf(index) > -1} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                  {item.children.map((child, childIndex) => 
                                      <ListItemButton
                                        onClick={() => {
                                          if(child.path.length > 0) {
                                            if(!child.external) {
                                              props.history.push(child.path)
                                            } else {
                                              openExternalLink(child.path)
                                            }
                                          }
                                        }}
                                        key={`child-${index}-${childIndex}`}
                                        sx={{ pl: 4 }}
                                      >
                                        {!child.nonMuiIcon && <ListItemIcon>{child.icon}</ListItemIcon>}
                                        {child.nonMuiIcon && <div style={{minWidth: 56, display: 'flex'}}><img style={{width: 24}} src={child.icon} alt={`${child.text}-logo`}/></div>}
                                        <ListItemText primary={child.text} />
                                      </ListItemButton>
                                  )}
                                </List>
                              </Collapse>
                            }
                          </Fragment>
                      )}
                  </List>
                </div>
            </Drawer>
        </React.Fragment>
    </div>
  );
}

export default withRouter(NavigationLeftSideBarDesktop)