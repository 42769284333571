interface INetworkToContractAddress {
  [key: number]: string
}

export const networkToTokenTransmuterContract : INetworkToContractAddress = {
  1: '0x38b1462dA63CB203AbCB710614BfeBd1F4439dC8',
  // 5: '0xA1f74958b9203a08f795FA7e7aBCa5bD75E59930'
};

export const networkToSEENContract : INetworkToContractAddress = {
  1: '0xCa3FE04C7Ee111F0bbb02C328c699226aCf9Fd33',
  // 5: '0x69083e08a563c0E2bf77d7Fe9AAD33Eda3AD55E4'
};

export const networkToPROContract : INetworkToContractAddress = {
  1: '0x226bb599a12C826476e3A771454697EA52E9E220',
  // 5: '0xc8F52f02E8Dd7506EB764871B0d518B6948d93aA'
};

// TODO disable testing config
export const supportedNetworkId = 1;