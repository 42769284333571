import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import BigNumber from 'bignumber.js';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useEthers, useContractFunction } from '@usedapp/core'
import { formatEther, formatUnits } from '@ethersproject/units'

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useSnackbar } from 'notistack';

import { Link } from 'react-router-dom';

import useWindowSize from '../hooks/useWindowSize';
import usePROBalance from '../hooks/usePROBalance';
import useSEENBalanceAndAllowance from '../hooks/useSEENBalanceAndAllowance';
import useTokenTransmuterVesterMetadata from '../hooks/useTokenTransmuterVesterMetadata';
import useTokenTransmuterContract from '../hooks/useTokenTransmuterContract';
import useSEENContract from '../hooks/useSEENContract';
import useTokenTransmuterEntryCloseDate from '../hooks/useTokenTransmuterEntryCloseDate';

import TwoToneBlock from '../components/TwoToneBlock';
import EthereumInteractionZone from '../components/EthereumInteractionZone';
import CountdownTimer from '../components/CountdownTimer';
import { ExternalLink } from '../components/ExternalLink';

import { networkToTokenTransmuterContract } from '../utils/constants';

import AbstractCircles from '../assets/svg/abstract-circles.svg';
import SeenIcon from '../assets/svg/seen-icon.svg';

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: 275,
        marginBottom: 15
    },
    titleContent: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    miniTitle: {
        marginBottom: theme.spacing(1),
    },
    lightText: {
        color: '#838383',
    },
    subtitle: {
        fontWeight: 500,
    },
    optionContainer: {
        marginTop: theme.spacing(4)
    },
    distributionContainer: {
        marginTop: theme.spacing(4)
    },
    orCircle: {
        width: '50px',
        height: '50px',
        backgroundColor: '#130B27',
        borderRadius: '100%',
        position: 'absolute',
        left: 'calc(50% + 16px)',
        top: 'calc(50% + 16px)',
        transform: 'translateX(-50%)translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 6px 20px 0px #8e98a063',
    },
    optionStats: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    abstractCircles: {
        position: 'fixed',
        zIndex: -1,
    },
    abstractCirclesPosition1: {
        left: 250
    },
    abstractCirclesPosition2: {
        right: 0,
        top: '50%'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    }
  }),
);

const HomePage = () => {
    const classes = useStyles();
    const { account, chainId } = useEthers();

    const windowSize = useWindowSize();

    const { enqueueSnackbar } = useSnackbar();

    const [isMobile, setIsMobile] = useState(false);
    const [isEntryClosed, setIsEntryClosed] = useState(false);

    const {readSuccess: entryCloseDateReadSuccess, entryCloseDate } = useTokenTransmuterEntryCloseDate();
    const {readSuccess: seenReadSuccess, balance: seenBalance, transmuterAllowance} = useSEENBalanceAndAllowance();
    const {readSuccess: proReadSuccess, balance: proBalance} = usePROBalance();
    const {
        readSuccess: vesterMetadataReadSuccess,
        addressVestingCode,
        addressToVestingStartTime,
        addressToVestingEndTime,
        addressToTotalAllocatedOutputToken,
        addressToTotalReleasedOutputToken,
        addressToClaimable,
    } = useTokenTransmuterVesterMetadata();

    useLayoutEffect(() => {
        let sizeConsiderMobile = 1200;
        if (windowSize.width && (windowSize.width <= sizeConsiderMobile)) {
            setIsMobile(true);
        }else{
            setIsMobile(false);
        }
    }, [windowSize.width, windowSize.height, setIsMobile])

    const tokenTransmuterContract = useTokenTransmuterContract(chainId);

    const [termsChecked, setTermsChecked] = useState(false);

    const handleTermsCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTermsChecked(event.target.checked);
    }

    /** LINEAR VEST LOGIC START **/

    const [isLinearVestBusy, setIsLinearVestBusy] = useState(false);
    const { state: transmuteLinearState, send: transmuteLinear } = useContractFunction(tokenTransmuterContract, 'transmuteLinear');

    const handleTransmuteLinear = async () => {
        setIsLinearVestBusy(true);
        await transmuteLinear(seenBalance);
        setIsLinearVestBusy(false);
    }
      
    /** LINEAR VEST ERROR HANDLING **/
  
    useEffect(() => {
        if(transmuteLinearState?.errorMessage && enqueueSnackbar) {
            enqueueSnackbar(transmuteLinearState?.errorMessage, { variant: 'error'})
        }
    }, [transmuteLinearState?.errorMessage, enqueueSnackbar])

    /** LINEAR VEST SUCCESS HANDLING **/

    useEffect(() => {
        if(transmuteLinearState?.status === 'Success' && enqueueSnackbar) {
            enqueueSnackbar('Successfully entered linear PRO vesting', { variant: 'success'});
        }
    }, [transmuteLinearState?.status, enqueueSnackbar])
  
    /** LINEAR VEST LOGIC END **/

    /** INSTANT VEST LOGIC START **/

    const [isInstantVestBusy, setIsInstantVestBusy] = useState(false);
    const { state: transmuteInstantState, send: transmuteInstant } = useContractFunction(tokenTransmuterContract, 'transmuteInstant');

    const handleTransmuteInstant = async () => {
        setIsInstantVestBusy(true);
        await transmuteInstant(seenBalance);
        setIsInstantVestBusy(false);
    }
      
    /** INSTANT VEST ERROR HANDLING **/
  
    useEffect(() => {
        if(transmuteInstantState?.errorMessage && enqueueSnackbar) {
            enqueueSnackbar(transmuteInstantState?.errorMessage, { variant: 'error'})
        }
    }, [transmuteInstantState?.errorMessage, enqueueSnackbar])

    /** INSTANT VEST SUCCESS HANDLING **/

    useEffect(() => {
        if(transmuteInstantState?.status === 'Success' && enqueueSnackbar) {
            enqueueSnackbar('Successfully performed instant PRO vesting', { variant: 'success'});
        }
    }, [transmuteInstantState?.status, enqueueSnackbar])
  
    /** INSTANT VEST LOGIC END **/

    /** APPROVE SEEN LOGIC START **/

    const [isApproveSEENBusy, setIsApproveSEENBusy] = useState(false);
    const seenContract = useSEENContract();
    const { state: approveSEENState, send: approveSEEN } = useContractFunction(seenContract, 'approve');

    const handleApproveSEEN = async () => {
        setIsApproveSEENBusy(true);
        await approveSEEN(tokenTransmuterContract.address, seenBalance);
        setIsApproveSEENBusy(false);
    }
      
    /** APPROVE SEEN ERROR HANDLING **/
  
    useEffect(() => {
        if(approveSEENState?.errorMessage && enqueueSnackbar) {
            enqueueSnackbar(approveSEENState?.errorMessage, { variant: 'error'})
        }
    }, [approveSEENState?.errorMessage, enqueueSnackbar])

    /** APPROVE SEEN SUCCESS HANDLING **/

    useEffect(() => {
    if(approveSEENState?.status === 'Success' && enqueueSnackbar) {
        enqueueSnackbar('Successfully granted SEEN approval', { variant: 'success'});
    }
    }, [approveSEENState?.status, enqueueSnackbar])
  
    /** APPROVE SEEN LOGIC END **/

    /** RELEASE VESTED TOKENS LOGIC START **/

    const [isReleaseVestedBusy, setIsReleaseVestedBusy] = useState(false);
    const { state: releaseVestedState, send: releaseVested } = useContractFunction(tokenTransmuterContract, 'releaseTransmutedLinear');

    const handleReleaseVested = async () => {
        setIsReleaseVestedBusy(true);
        await releaseVested();
        setIsReleaseVestedBusy(false);
    }
      
    /** RELEASE VESTED ERROR HANDLING **/
  
    useEffect(() => {
        if(releaseVestedState?.errorMessage && enqueueSnackbar) {
            enqueueSnackbar(releaseVestedState?.errorMessage, { variant: 'error'})
        }
    }, [releaseVestedState?.errorMessage, enqueueSnackbar])

    /** RELEASE VESTED SUCCESS HANDLING **/

    useEffect(() => {
        if(releaseVestedState?.status === 'Success' && enqueueSnackbar) {
            enqueueSnackbar('Successfully released vested PRO', { variant: 'success'});
        }
    }, [releaseVestedState?.status, enqueueSnackbar])
  
    /** RELEASE VESTED TOKENS LOGIC END **/

    return (
        <Container maxWidth="lg" style={{maxWidth: 1000, marginBottom: 65}}>
            <img className={[classes.abstractCircles, classes.abstractCirclesPosition1].join(' ')} alt="abstract ripple circles" src={AbstractCircles} />
            <img className={[classes.abstractCircles, classes.abstractCirclesPosition2].join(' ')} alt="abstract ripple circles" src={AbstractCircles} />
            <div className={classes.titleContent}>
                <Typography variant="h3" className={classes.title} style={{fontWeight: 'bold'}}>SEEN 🤝 PROPY</Typography>
                <Typography variant="h6" className={[classes.subtitle, classes.lightText].join(' ')}>With the <ExternalLink style={{color: '#26bb39', fontWeight: 'bold'}} href="https://snapshot.org/#/seenhaus.eth/proposal/0xa3eda529c4404d4edf961efa08ba3f7e719974dec623ebb2b012fc165832b99a">transition</ExternalLink> to Propy, SEEN may be burnt in exchange for PRO via one of two options:</Typography>
            </div>
            {addressVestingCode === 0 &&
                <div className={classes.optionContainer}>
                    <TwoToneBlock
                        lightToneContent={
                            <>
                                <Typography variant="h5" className={classes.miniTitle} style={{fontWeight: 'bold'}}>SEEN Balance</Typography>
                                <Typography variant="subtitle1" className={[classes.subtitle, classes.lightText].join(' ')}>This is your current balance of SEEN tokens in your connected wallet, eligible to be transitioned into PRO.</Typography>
                            </>
                        }
                        darkToneContent={
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Your SEEN</Typography>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img style={{width: 20, height: 20, marginRight: 6}} src={SeenIcon} alt="SEEN token icon"/>
                                    <Typography variant="h5" style={{fontWeight: 'bold'}}>{seenBalance ? new BigNumber(formatEther(seenBalance)).decimalPlaces(2).toNumber() : 0} SEEN</Typography>
                                </div>
                                <Typography variant="overline" style={{textTransform: 'none', color: '#ff4500', marginTop: 5}} className={[classes.lightText, 'stat-bundle-text'].join(' ')}><b>Please note: both transition options below will no longer be operational after {new Date(1703106000 * 1000).toString()}.</b></Typography>
                            </div>
                        }
                        defaultOrientation="row"
                        isMobile={isMobile}
                    />
                </div>
            }
            {account && 
                <div className={classes.optionContainer}>
                    <TwoToneBlock
                        lightToneContent={
                            <>
                                <Typography variant="h5" className={classes.miniTitle} style={{fontWeight: 'bold'}}>Terms & Conditions</Typography>
                                <Typography variant="subtitle1" className={[classes.subtitle, classes.lightText].join(' ')}>By using this platform you agree to the <Link className={'no-decorate'} style={{color: '#26bb39', fontWeight: 'bold'}} to={`./terms-and-conditions`}>terms and conditions</Link> of use.</Typography>
                                <Typography variant="h5" className={classes.miniTitle} style={{fontWeight: 'bold', marginTop: 12}}>Audit</Typography>
                                <Typography variant="subtitle1" className={[classes.subtitle, classes.lightText].join(' ')}>The underlying contract has been <ExternalLink style={{color: '#26bb39', fontWeight: 'bold'}} href="https://seenhaus.mypinata.cloud/ipfs/QmXo6747PkxpifxVegMgSEJ7XGW9WgJepye5fWDNqQ3yyg">audited</ExternalLink>, however, an audit is not a guarantee of contract safety.</Typography>
                            </>
                        }
                        darkToneContent={
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Do you accept the terms and conditions?</Typography>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={termsChecked}
                                                    onChange={handleTermsCheckChange}
                                                    sx={{
                                                        color: "#26bb39",
                                                        '&.Mui-checked': {
                                                            color: "#26bb39",
                                                        },
                                                    }} 
                                                />
                                            }
                                            label="I have read & accept the terms and conditions"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        }
                        defaultOrientation="row"
                        isMobile={isMobile}
                    />
                </div>
            }
            {addressVestingCode === 2 &&
                <div className={classes.distributionContainer}>
                    <TwoToneBlock
                        lightToneContent={
                            <>
                                <Typography variant="h5" className={classes.miniTitle} style={{fontWeight: 'bold'}}>Vested PRO</Typography>
                                <Typography variant="subtitle1" className={[classes.subtitle, classes.lightText].join(' ')}>Clicking the "claim" button will release any claimable PRO to your account.</Typography>
                                <EthereumInteractionZone connectButtonClass={[classes.actionButton, 'gradient-button'].join(" ")}>
                                    <>
                                        <Button disabled={isReleaseVestedBusy || (Number(addressToClaimable) === 0) || !termsChecked} onClick={() => handleReleaseVested()} className={[classes.actionButton, 'gradient-button', (isReleaseVestedBusy || (Number(addressToClaimable) === 0) || !termsChecked) && 'disable-button'].join(' ')} variant="outlined" color="inherit">{isReleaseVestedBusy ? 'Claiming Tokens...' : 'Claim Tokens'}</Button>
                                        {!termsChecked && <Typography variant="subtitle2" style={{marginTop: 6, color: '#ff4500'}} className={[classes.subtitle, classes.lightText].join(' ')}>Terms & conditions must be accepted to claim</Typography>}
                                    </>
                                </EthereumInteractionZone>
                            </>
                        }
                        darkToneContent={
                            <div style={{display: 'flex', height: '100%'}}>
                                <Grid container style={{position: 'relative'}} spacing={0}>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Claimable</Typography>
                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToClaimable ? Number(formatUnits(addressToClaimable, 8)).toFixed(Number(formatUnits(addressToClaimable, 8)) < 10 ? 4 : 2) : 0} PRO</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Remaining (Vested)</Typography>
                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(addressToTotalAllocatedOutputToken).minus(addressToTotalReleasedOutputToken ? addressToTotalReleasedOutputToken : 0).toString(), 8)).decimalPlaces(2).toNumber() : 0} PRO</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{marginTop: 10, marginBottom: 10}}>
                                            <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Daily Rate</Typography>
                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(addressToTotalAllocatedOutputToken, 8)).dividedBy(365).decimalPlaces(4).toString() : 0} PRO</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div style={{marginTop: 10, marginBottom: 10}}>
                                            <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Remaining Duration</Typography>
                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                                {addressToVestingEndTime && <CountdownTimer countdownToTimeUnix={addressToVestingEndTime}/>}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>PRO Released</Typography>
                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(addressToTotalReleasedOutputToken ? addressToTotalReleasedOutputToken : 0).toString(), 8)).decimalPlaces(2).toNumber() : 0} PRO</Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div>
                                            <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>SEEN Burnt</Typography>
                                            {addressVestingCode === 2 && <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ?  new BigNumber(formatUnits(new BigNumber(addressToTotalAllocatedOutputToken).toString(), 8)).dividedBy(3).decimalPlaces(2).toNumber() : 0} SEEN</Typography>}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        defaultOrientation="row"
                        isMobile={isMobile}
                    />
                </div>
            }
            <div className={classes.optionContainer}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container style={{position: 'relative'}} spacing={4}>
                        <Grid item xs={12} md={6}>
                            <TwoToneBlock
                                selectedBlock={addressVestingCode === 2}
                                lightToneContent={
                                    <div style={{position: 'relative', ...(addressVestingCode === 1 && {opacity: 0.3})}}>
                                        <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Option 1</Typography>
                                        <Typography variant="h5" className={classes.miniTitle} style={{fontWeight: 'bold'}}>Linear Vesting</Typography>
                                        {entryCloseDate &&
                                            <div style={{marginTop: 10, marginBottom: 10}}>
                                                <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                                    Option Selection Deadline
                                                    <Tooltip
                                                        followCursor={true}
                                                        title={
                                                            <React.Fragment>
                                                              {!isEntryClosed && <Typography color="inherit"><b>Once the deadline is reached, it will no longer be possible to opt into this option. If you have already opted into an option on-chain, you can ignore this deadline.</b></Typography>}
                                                              {isEntryClosed && <Typography color="inherit"><b>This option selection deadline has been reached, it is no longer possible to opt into this option.</b></Typography>}
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <HelpOutlineIcon fontSize='small' style={{marginLeft: 3}} />
                                                    </Tooltip>
                                                </Typography>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                                    {entryCloseDate && <CountdownTimer completedLabel="Option Selection Expired" countdownToTimeUnix={entryCloseDate}/>}
                                                </Typography>
                                            </div>
                                        }
                                        <Typography variant="subtitle1" className={[classes.subtitle, classes.lightText].join(' ')}>You will receive 3 $PRO for each 1 $SEEN. $PRO tokens unlock linearly over 12 months.</Typography>
                                        <EthereumInteractionZone connectButtonClass={[classes.actionButton, 'gradient-button'].join(" ")}>
                                            <>
                                                {
                                                    account && transmuterAllowance && seenBalance && addressVestingCode !== 2 && new BigNumber(transmuterAllowance).isGreaterThanOrEqualTo(new BigNumber(seenBalance)) && 
                                                        <Button onClick={() => handleTransmuteLinear()} disabled={isLinearVestBusy || isInstantVestBusy || (Number(seenBalance) === 0) || !termsChecked} className={[classes.actionButton, 'gradient-button', (isLinearVestBusy || isInstantVestBusy || (Number(seenBalance) === 0) || !termsChecked) && 'disable-button'].join(' ')} variant="outlined" color="inherit">{isLinearVestBusy ? 'Entering Linear Vesting...' : 'Enter Linear Vesting'}</Button>
                                                }
                                                {
                                                    account && transmuterAllowance && seenBalance && addressVestingCode !== 2 && new BigNumber(transmuterAllowance).isLessThan(new BigNumber(seenBalance)) && 
                                                        <Button onClick={() => handleApproveSEEN()} disabled={isApproveSEENBusy || !termsChecked} className={[classes.actionButton, 'gradient-button', (isApproveSEENBusy || !termsChecked) && 'disable-button'].join(' ')} variant="outlined" color="inherit">{isApproveSEENBusy ? 'Granting SEEN Approval...' : 'Grant SEEN Approval'}</Button>
                                                }
                                                {
                                                    account && addressVestingCode && addressVestingCode === 2 ? <Button onClick={() => handleApproveSEEN()} disabled={true} className={[classes.actionButton, 'gradient-button', 'disable-button'].join(' ')} variant="outlined" color="inherit">Entered Linear Vesting</Button> : null
                                                }
                                            </>
                                        </EthereumInteractionZone>
                                    </div>
                                }
                                darkToneContent={
                                    <Grid container style={{position: 'relative', ...(addressVestingCode === 1 && {opacity: 0.3})}} spacing={0}>
                                        <Grid item xs={6}>
                                            <div>
                                                <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Release Schedule</Typography>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>Over 365 days</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div>
                                                <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>SEEN:PRO Ratio</Typography>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>1:3</Typography>
                                            </div>
                                        </Grid>
                                        {account && 
                                            <>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>{addressVestingCode > 0 ? `Burnt` : `Burn`}</Typography>
                                                        {addressVestingCode === 0 && <Typography variant="h5" style={{fontWeight: 'bold'}}>{seenBalance ? new BigNumber(formatEther(new BigNumber(seenBalance.toString()).toString())).decimalPlaces(2).toNumber() : 0} SEEN</Typography>}
                                                        {addressVestingCode === 1 && <Typography variant="h5" style={{fontWeight: 'bold'}}>0 SEEN</Typography>}
                                                        {addressVestingCode === 2 && <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(addressToTotalAllocatedOutputToken).toString(), 8)).dividedBy(3).decimalPlaces(2).toNumber() : 0} SEEN</Typography>}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {addressVestingCode === 2 &&
                                                        <div>
                                                            <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Vested</Typography>
                                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(addressToTotalAllocatedOutputToken).toString(), 8)).decimalPlaces(2).toNumber() : 0} PRO</Typography>
                                                        </div>
                                                    }
                                                    {addressVestingCode !== 2 &&
                                                        <div>
                                                            <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Vest</Typography>
                                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{seenBalance ? new BigNumber(formatEther(new BigNumber(seenBalance.toString()).multipliedBy(3).toString())).decimalPlaces(2).toNumber() : 0} PRO</Typography>
                                                        </div>
                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                }
                                defaultOrientation="column"
                                isMobile={isMobile}
                            />
                        </Grid>
                        {addressVestingCode === 0 && <div className={classes.orCircle}><Typography variant="h6" style={{color: 'white'}}>or</Typography></div>}
                        <Grid item xs={12} md={6}>
                            <TwoToneBlock
                                selectedBlock={addressVestingCode === 1}
                                lightToneContent={
                                    <div style={{position: 'relative', ...(addressVestingCode === 2 && {opacity: 0.3})}}>
                                        <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Option 2</Typography>
                                        <Typography variant="h5" className={classes.miniTitle} style={{fontWeight: 'bold'}}>Instant Transition</Typography>
                                        {entryCloseDate &&
                                            <div style={{marginTop: 10, marginBottom: 10}}>
                                                <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                                    Option Selection Deadline
                                                    <Tooltip
                                                        followCursor={true}
                                                        title={
                                                            <React.Fragment>
                                                              {!isEntryClosed && <Typography color="inherit"><b>Once the deadline is reached, it will no longer be possible to opt into this option. If you have already opted into an option on-chain, you can ignore this deadline.</b></Typography>}
                                                              {isEntryClosed && <Typography color="inherit"><b>This option selection deadline has been reached, it is no longer possible to opt into this option.</b></Typography>}
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <HelpOutlineIcon fontSize='small' style={{marginLeft: 3}} />
                                                    </Tooltip>
                                                </Typography>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>
                                                    {entryCloseDate && <CountdownTimer onCompletion={(completionState: boolean) => setIsEntryClosed(completionState)} completedLabel="Option Selection Expired" countdownToTimeUnix={entryCloseDate}/>}
                                                </Typography>
                                            </div>
                                        }
                                        <Typography variant="subtitle1" className={[classes.subtitle, classes.lightText].join(' ')}>You will receive 1 $PRO for each 1 $SEEN. $PRO tokens are unlocked immediately.</Typography>
                                        <EthereumInteractionZone connectButtonClass={[classes.actionButton, 'gradient-button'].join(" ")}>
                                            <>
                                                {
                                                    account && transmuterAllowance && seenBalance && addressVestingCode !== 1 && new BigNumber(transmuterAllowance).isGreaterThanOrEqualTo(new BigNumber(seenBalance)) && 
                                                        <Button onClick={() => handleTransmuteInstant()} disabled={isInstantVestBusy || isLinearVestBusy || (Number(seenBalance) === 0) || !termsChecked} className={[classes.actionButton, 'gradient-button', (isInstantVestBusy || isLinearVestBusy || (Number(seenBalance) === 0) || !termsChecked) && 'disable-button'].join(' ')} variant="outlined" color="inherit">{isInstantVestBusy ? 'Performing Instant Vesting...' : 'Perform Instant Vesting'}</Button>
                                                }
                                                {
                                                    account && transmuterAllowance && seenBalance && addressVestingCode !== 1 && new BigNumber(transmuterAllowance).isLessThan(new BigNumber(seenBalance)) && 
                                                        <Button onClick={() => handleApproveSEEN()} disabled={isApproveSEENBusy || !termsChecked} className={[classes.actionButton, 'gradient-button', (isApproveSEENBusy || !termsChecked) && 'disable-button'].join(' ')} variant="outlined" color="inherit">{isApproveSEENBusy ? 'Granting SEEN Approval...' : 'Grant SEEN Approval'}</Button>
                                                }
                                                {
                                                    account && addressVestingCode && addressVestingCode === 1 ? <Button onClick={() => handleApproveSEEN()} disabled={true} className={[classes.actionButton, 'gradient-button', 'disable-button'].join(' ')} variant="outlined" color="inherit">Performed Instant Vesting</Button> : null
                                                }
                                            </>
                                        </EthereumInteractionZone>
                                    </div>
                                }
                                darkToneContent={
                                    <Grid container style={{position: 'relative', ...(addressVestingCode === 2 && {opacity: 0.3})}} spacing={0}>
                                        <Grid item xs={6}>
                                            <div>
                                                <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Release Schedule</Typography>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>Immediate</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div>
                                                <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>SEEN:PRO Ratio</Typography>
                                                <Typography variant="h5" style={{fontWeight: 'bold'}}>1:1</Typography>
                                            </div>
                                        </Grid>
                                        {account && 
                                            <>
                                                <Grid item xs={6}>
                                                    <div>
                                                        <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>{addressVestingCode > 0 ? `Burnt` : `Burn`}</Typography>
                                                        {addressVestingCode === 0 && <Typography variant="h5" style={{fontWeight: 'bold'}}>{seenBalance ? new BigNumber(formatEther(new BigNumber(seenBalance.toString()).toString())).decimalPlaces(2).toNumber() : 0} SEEN</Typography>}
                                                        {addressVestingCode === 1 && <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(addressToTotalAllocatedOutputToken).toString(), 8)).decimalPlaces(2).toNumber() : 0} SEEN</Typography>}
                                                        {addressVestingCode === 2 && <Typography variant="h5" style={{fontWeight: 'bold'}}>0 SEEN</Typography>}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {addressVestingCode !== 1 &&
                                                        <div>
                                                            <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Receive</Typography>
                                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{seenBalance ? new BigNumber(formatEther(seenBalance.toString())).decimalPlaces(2).toNumber() : 0} PRO</Typography>
                                                        </div>
                                                    }
                                                    {addressVestingCode === 1 &&
                                                        <div>
                                                            <Typography variant="overline" className={[classes.miniTitle, classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Received</Typography>
                                                            <Typography variant="h5" style={{fontWeight: 'bold'}}>{addressToTotalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(addressToTotalAllocatedOutputToken).toString(), 8)).decimalPlaces(2).toNumber() : 0} PRO</Typography>
                                                        </div>
                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                }
                                defaultOrientation="column"
                                isMobile={isMobile}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </div>
            {/* <pre>
                {
                    JSON.stringify({
                        proReadSuccess,
                        proBalance,
                        seenReadSuccess,
                        seenBalance,
                        vesterMetadataReadSuccess,
                        transmuterAllowance,
                        addressVestingCode,
                        addressToVestingStartTime,
                        addressToVestingEndTime,
                        addressToTotalAllocatedOutputToken,
                        addressToTotalReleasedOutputToken,
                        addressToClaimable
                    }, null, 4)
                }
            </pre> */}
        </Container>
    )
};

export default HomePage;