import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { supportedNetworkId } from '../utils/constants';

import AbstractCircles from '../assets/svg/abstract-circles.svg';

interface IIncorrectNetworkModalProps {
  chainId?: number;
  dismiss?: () => void;
  requestNetworkSwitch?: React.MutableRefObject<() => void>;
  showModal: boolean;
  provider?: any;
}

const IncorrectNetworkModal = (props: IIncorrectNetworkModalProps) => {

  const { showModal, requestNetworkSwitch, provider } = props;

  const [showCheckWallet, setShowCheckWallet] = useState(false);

  useEffect(() => {
    if(showModal === false) {
      setShowCheckWallet(false);
    }
  }, [showModal])

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          // backgroundColor: 'black',
          backgroundImage: 'none',
          border: '1px solid lightgrey',
        }
      }}
      open={showModal}
      scroll={'body'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <img style={{position: 'absolute', left: '-55%', top: '-100%', opacity: 0.75}} alt="abstract ripple circles" src={AbstractCircles} />
      <DialogTitle id="alert-dialog-title" style={{textAlign: "center"}}>
        <Typography variant="h3" style={{ fontWeight: 'bold', color: '#000', position: 'relative'}}>{"Incorrect Network"}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{textAlign: "center", position: 'relative'}}>
          <Typography variant="body1" style={{color: 'black', marginBottom: 15}}>
            You have switched your wallet provider (e.g. MetaMask) to the incorrect network, please either switch your wallet provider back to Ethereum Mainnet Network or reload this webpage in order for the website to continue to operate correctly.
          </Typography>
          <div className="flex-col">
            <Button
              onClick={(event) => {
                if(provider) {
                  provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x' + supportedNetworkId }],
                  })
                  setShowCheckWallet(true);
                }
              }}
              style={{marginBottom: 15}}
              sx={{ minWidth: '50%' }}
              variant="outlined"
              className="gradient-button"
              color="inherit"
              aria-label="switch network"
            >
              switch to mainnet
            </Button>
            {showCheckWallet && 
              <Typography variant="body1" style={{color: '#000', marginBottom: 15}}>
                Check wallet to switch
              </Typography>
            }
            <Button
              onClick={() => {window.location.reload();}}
              sx={{ minWidth: '50%' }}
              variant="outlined"
              className="gradient-button"
              color="inherit"
              aria-label="switch network"
            >
              reload
            </Button>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )

}

export default IncorrectNetworkModal;