import { useState, useEffect, useMemo } from "react";
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useEthers } from '@usedapp/core';

import { networkToSEENContract, supportedNetworkId } from '../utils/constants';

import ERC20ABI from '../utils/abis/ERC20ABI.json';

const useSEENContract = () => {
  const { chainId } = useEthers()
  const erc20Interface = useMemo(() => new utils.Interface(ERC20ABI), []);
  const initialContract = new Contract(networkToSEENContract[supportedNetworkId], erc20Interface);
  const [seenContract, setSEENContract] = useState<Contract>(initialContract);
  useEffect(() => {
    let mounted = true;
    const constructContract = () => {
      const contractAddress = networkToSEENContract[supportedNetworkId]
      if(contractAddress){
        let constructedContract = new Contract(contractAddress, erc20Interface)
        if(mounted) {
          setSEENContract(constructedContract)
        }
      }
    }
    constructContract();
    return () => {
      mounted = false
    };
  }, [chainId, erc20Interface]);
  return seenContract;
}

export default useSEENContract;