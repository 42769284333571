import { useState, useEffect } from "react";
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';

import { networkToTokenTransmuterContract, supportedNetworkId } from '../utils/constants';
import TokenTransmuterABI from '../utils/abis/TokenTransmuterABI.json';

const useTokenTransmuterContract = (networkId: undefined | number) => {
  const transmuterInterface = new utils.Interface(TokenTransmuterABI)
  const defaultContractAddress = networkToTokenTransmuterContract[supportedNetworkId];
  const initialContract = new Contract(defaultContractAddress, transmuterInterface);
  const [tokenTransmuterContract, setTokenTransmuterContract] = useState<Contract>(initialContract);
  useEffect(() => {
    let mounted = true;
    const constructContract = () => {
      if(networkId) {
        const contractAddress = networkToTokenTransmuterContract[supportedNetworkId]
        if(contractAddress){
          let constructedContract = new Contract(contractAddress, transmuterInterface)
          if(mounted) {
            setTokenTransmuterContract(constructedContract)
          }
        }
      }
    }
    constructContract();
    return () => {
      mounted = false
    };
  }, [networkId]);
  return tokenTransmuterContract;
}

export default useTokenTransmuterContract;