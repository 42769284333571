import React, {useState, useEffect, useLayoutEffect} from 'react';
import {Route, withRouter, Switch, RouteComponentProps} from 'react-router-dom';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Navigation from './Navigation';
import NavigationLeftSideBarDesktop from './NavigationLeftSideBarDesktop';
import HomePage from '../pages/HomePage';
import TermsPage from '../pages/TermsAndConditionsPage';
import ProAllocationPage from '../pages/ProAllocationPage';

import useWindowSize from '../hooks/useWindowSize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    //   paddingLeft: theme.spacing(3),
    //   paddingRight: theme.spacing(3),
        display: 'flex',
    },
  }),
);

const PageContainer = () => {

    const classes = useStyles();
    const windowSize = useWindowSize();

    const [showDesktopMenu, setShowDesktopMenu] = useState(true);

    useLayoutEffect(() => {
        let sizeShowDesktopMenu = 1100;
        if (windowSize.width && (windowSize.width <= sizeShowDesktopMenu)) {
          setShowDesktopMenu(false);
        }else{
          setShowDesktopMenu(true);
        }
    }, [windowSize.width, windowSize.height, setShowDesktopMenu]);

    return (
        <Navigation>
            <div className={classes.root}>
                {showDesktopMenu && <NavigationLeftSideBarDesktop/>}
                <Switch>
                    <Route path="/" exact render={(props) => homeRoute(props)} />
                    <Route path="/terms-and-conditions" exact render={(props) => termsRoute(props)} />
                    <Route path="/pro-claim-tracker" exact render={(props) => proAllocationRoute(props)} />
                </Switch>
            </div>
        </Navigation>
    )
}

const homeRoute = (props: RouteComponentProps) => {
    return (
        <HomePage/>
    )
}

const termsRoute = (props: RouteComponentProps) => {
    return (
        <TermsPage/>
    )
}

const proAllocationRoute = (props: RouteComponentProps) => {
    return (
        <ProAllocationPage />
    )
}

export default withRouter(PageContainer);