import { useState, useEffect } from "react";
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useCall, useEthers } from '@usedapp/core';
import BigNumber from 'bignumber.js';

import { networkToPROContract } from '../utils/constants';
import ERC20ABI from '../utils/abis/ERC20ABI.json';

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useERC20Balance = () => {
  const { account, chainId } = useEthers()

  const [contractAddress, setContractAddress] = useState<undefined | string>();

  useEffect(() => {
    let mounted = true;
    const constructContract = () => {
      if(chainId) {
        const contractAddress = networkToPROContract[chainId];
        if(mounted) {
          setContractAddress(contractAddress)
        }
      }
    }
    constructContract();
    return () => {
      mounted = false
    };
  }, [chainId]);

  const contractInterface = new utils.Interface(ERC20ABI)
  const { value, error } = useCall(contractAddress && account && chainId && {
    contract: new Contract(contractAddress, contractInterface),
    method: 'balanceOf',
    args: [account]
  }) ?? {}

  if(error) {
    console.error(error.message)
    return {
      readSuccess: false
    }
  }
  return {
    balance: value?.[0] ? new BigNumber(value?.[0].toString()).toString() : "0",
    readSuccess: value ? true : false,
  }
}

export default useERC20Balance;