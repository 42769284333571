import { useState, useEffect } from "react";
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useCall, useEthers } from '@usedapp/core';
import BigNumber from 'bignumber.js';

import { networkToSEENContract, supportedNetworkId, networkToTokenTransmuterContract } from '../utils/constants';
import ERC20ABI from '../utils/abis/ERC20ABI.json';

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useERC20Balance = () => {
  const { account, chainId } = useEthers()

  const [contractAddress, setContractAddress] = useState<undefined | string>();
  const [transmuterAddress, setTransmuterAddress] = useState<undefined | string>();

  useEffect(() => {
    let mounted = true;
    const constructContract = () => {
      if(chainId) {
        const contractAddress = networkToSEENContract[supportedNetworkId];
        const transmuterAddress = networkToTokenTransmuterContract[supportedNetworkId];
        if(mounted) {
          setContractAddress(contractAddress);
          setTransmuterAddress(transmuterAddress);
        }
      }
    }
    constructContract();
    return () => {
      mounted = false
    };
  }, [chainId]);

  const contractInterface = new utils.Interface(ERC20ABI)
  const { value, error } = useCall(contractAddress && account && chainId && {
    contract: new Contract(contractAddress, contractInterface),
    method: 'balanceOf',
    args: [account]
  }) ?? {}

  const { value: transmuterAllowance, error: transmuterAllowanceError } = useCall(contractAddress && transmuterAddress && account && chainId && {
    contract: new Contract(contractAddress, contractInterface),
    method: 'allowance',
    args: [account, transmuterAddress]
  }) ?? {}

  if(error || transmuterAllowanceError) {
    console.error(error ? error.message : '');
    console.error(transmuterAllowanceError ? transmuterAllowanceError.message : '');
    return {
      readSuccess: false
    }
  }
  return {
    balance: value?.[0] ? new BigNumber(value?.[0].toString()).toString() : "0",
    transmuterAllowance: transmuterAllowance?.[0] ? new BigNumber(transmuterAllowance?.[0].toString()).toString() : "0",
    readSuccess: value ? true : false,
  }
}

export default useERC20Balance;