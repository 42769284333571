import React, { useState, useEffect, useLayoutEffect } from 'react';

import { withRouter, RouteComponentProps } from "react-router";

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeIcon from '@mui/icons-material/NightsStay';
import LightModeIcon from '@mui/icons-material/WbSunny';

import LogoDarkMode from '../assets/svg/seen-logo-white.svg'
import LogoLightMode from '../assets/svg/seen-logo-black.svg'

import useWindowSize from '../hooks/useWindowSize';

import { Web3ModalButton } from './Web3ModalButton';
import { PropsFromRedux } from '../containers/NavigationTopBarContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      cursor: 'pointer',
    },
    margin: {
      margin: theme.spacing(1),
    },
    appBar: {
      background: 'white',
      borderTop: '1px solid #dfe4e9',
      borderBottom: '1px solid #dfe4e9',
      boxShadow: 'none',
    },
    appBarMobile: {
      top: '100%',
      transform: 'translateY(-100%)',
      padding: 5,
      background: 'white',
    },
    toolBar: {
      // maxWidth: '1000px',
      // marginLeft: 'auto',
      // marginRight: 'auto',
      width: '100%',
    }
  }),
);

const NavigationTopBar = (props: PropsFromRedux & RouteComponentProps) => {
  const classes = useStyles()

  const [localShowLeftMenu, setLocalShowLeftMenu] = useState(props.showLeftMenu)
  const [localDarkMode, setLocalDarkMode] = useState(false)

  useEffect(() => {
    setLocalShowLeftMenu(props.showLeftMenu)
  }, [props.showLeftMenu])

  const windowSize = useWindowSize();

  const [bottomConnectButton, setBottomConnectButton] = useState(false);
  const [showDesktopMenu, setShowDesktopMenu] = useState(true);

  useLayoutEffect(() => {
    let sizeBottomConnectButton = 600;
    let sizeShowDesktopMenu = 1100;
    if (windowSize.width && (windowSize.width <= sizeBottomConnectButton)) {
      setBottomConnectButton(true);
    }else{
      setBottomConnectButton(false);
    }
    if (windowSize.width && (windowSize.width <= sizeShowDesktopMenu)) {
      setShowDesktopMenu(false);
    }else{
      setShowDesktopMenu(true);
    }
  }, [windowSize.width, windowSize.height, setBottomConnectButton, setShowDesktopMenu]);

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} style={{color: localDarkMode ? 'white' : 'black'}} position="fixed">
        <Toolbar className={classes.toolBar}>
          {!showDesktopMenu && 
            <IconButton
              onClick={() => props.setShowLeftMenu(!localShowLeftMenu)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              size="large">
              <MenuIcon />
            </IconButton>
          }
          <div style={{height: 44}}>
            <img onClick={() => props.history.push('/')} height={'25px'} style={{marginRight: '10px', cursor: 'pointer'}} src={localDarkMode ? LogoDarkMode : LogoLightMode} alt="logo" />
            <Typography onClick={() => props.history.push('/')} variant="h6" style={{fontFamily: 'monospace', lineHeight: 0.5}} className={classes.title}>
              DAO HUB
            </Typography>
          </div>
          <Typography onClick={() => props.history.push('/')} variant="h6" style={{fontFamily: 'monospace'}} className={classes.title}>

          </Typography>
          {!bottomConnectButton && <Web3ModalButton/>}
          {/* <IconButton
            color="inherit"
            onClick={() => props.setDarkMode(!localDarkMode)}
            aria-label="delete"
            className={classes.margin}
            size="large">
            {localDarkMode ? <LightModeIcon/> : <DarkModeIcon />}
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <AppBar className={classes.appBar} style={{opacity: 0}} position="static">
        <Toolbar className={classes.toolBar}/>
      </AppBar>
      {bottomConnectButton && 
        <AppBar className={classes.appBarMobile} style={{color: localDarkMode ? 'white' : 'black'}} position="fixed">
          <Web3ModalButton isMobile={bottomConnectButton}/>
        </AppBar>
      }
    </div>
  );
}

export default withRouter(NavigationTopBar)