import { useState, useEffect } from "react";
import { utils } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { useCall, useEthers } from '@usedapp/core';
import BigNumber from 'bignumber.js';

import { networkToTokenTransmuterContract } from '../utils/constants';

import TokenTransmuterABI from '../utils/abis/TokenTransmuterABI.json';

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useTokenTransmuterVesterMetadata = () => {
  const { chainId } = useEthers()

  const [transmuterContractAddress, setTransmuterContractAddress] = useState<undefined | string>();

  useEffect(() => {
    let mounted = true;
    const constructContract = () => {
      if(chainId) {
        const contractAddress = networkToTokenTransmuterContract[chainId];
        if(mounted) {
          setTransmuterContractAddress(contractAddress);
        }
      }
    }
    constructContract();
    return () => {
      mounted = false
    };
  }, [chainId]);

  const transmuterInterface = new utils.Interface(TokenTransmuterABI)

  const { value: totalAllocatedOutputToken, error: errorAddressToTotalAllocatedOutputToken } = useCall(transmuterContractAddress && chainId && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'totalAllocatedOutputToken',
    args: []
  }) ?? {}
  
  const { value: totalReleasedOutputToken, error: errorAddressToTotalReleasedOutputToken } = useCall(transmuterContractAddress && chainId && {
    contract: new Contract(transmuterContractAddress, transmuterInterface),
    method: 'totalReleasedOutputToken',
    args: []
  }) ?? {}

  if(errorAddressToTotalAllocatedOutputToken || errorAddressToTotalReleasedOutputToken) {
    console.error(`errorAddressToTotalAllocatedOutputToken: ${errorAddressToTotalAllocatedOutputToken}`);
    console.error(`errorAddressToTotalReleasedOutputToken: ${errorAddressToTotalReleasedOutputToken}`);
    return {
      readSuccess: false
    }
  }
  return {

    totalAllocatedOutputToken: totalAllocatedOutputToken?.[0] ? new BigNumber(totalAllocatedOutputToken?.[0].toString()).toString() : 0,
    totalReleasedOutputToken: totalReleasedOutputToken?.[0] ? new BigNumber(totalReleasedOutputToken?.[0].toString()).toString() : 0,
    readSuccess: (!errorAddressToTotalAllocatedOutputToken && !errorAddressToTotalReleasedOutputToken) ? true : false
  }
}

export default useTokenTransmuterVesterMetadata;