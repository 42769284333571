import React, { useState, useEffect } from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({

  }),
);

interface ICountdownTimerProps {
  countdownToTimeUnix: number
  completedLabel?: string
  onCompletion?: (arg0: boolean) => void
}

const CountdownTimer = (props: ICountdownTimerProps) => {

  const { 
    countdownToTimeUnix,
    completedLabel,
    onCompletion,
  } = props;

  const [isCountdownEnded, setIsCountdownEnded] = useState(true);
  const [countdownReadable, setCountdownReadable] = useState<undefined | string>('Loading...');

  useEffect(() => {
    const interval = setInterval(() => {
      let currentUnix = new Date().getTime() / 1000;
      let countdownSeconds = Number(countdownToTimeUnix) - Number(currentUnix.toFixed(0));
      if(countdownSeconds >= 0) {
        if(isCountdownEnded) {
          setIsCountdownEnded(false);
          if(onCompletion) {
            onCompletion(false);
          }
        }
        let days = Math.floor(countdownSeconds / 86400);
        countdownSeconds -= days * 86400;
        // calculate (and subtract) whole hours
        let hours = Math.floor(countdownSeconds / 3600) % 24;
        countdownSeconds -= hours * 3600;
        // calculate (and subtract) whole minutes
        let minutes = Math.floor(countdownSeconds / 60) % 60;
        countdownSeconds -= minutes * 60;
        // what's left is seconds
        let seconds = countdownSeconds % 60;  // in theory the modulus is not required
        let countdownReadableString = `
          ${days > 0 ? `${days} ${days > 1 ? 'days' : 'day'}` : ``}
          ${((hours > 0)) ? `${hours} ${hours > 1 ? 'hrs' : 'hr'}` : ``}
          ${((minutes > 0) && (days === 0)) ? `${minutes} ${minutes > 1 ? 'mins' : 'min'}` : ``}
          ${((seconds >= 0) && ((hours <= 1) || (minutes === 0)) && ((days <= 1) || (minutes === 0))) ? `${seconds} ${seconds !== 1 ? 'secs' : 'sec'}` : ``}
        `;
        setCountdownReadable(countdownReadableString);
      } else {
        setIsCountdownEnded(true);
        setCountdownReadable(completedLabel ? completedLabel : `Complete`);
        if(onCompletion) {
          onCompletion(true);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <>
      {countdownReadable}
    </>
  );
}

export default CountdownTimer;