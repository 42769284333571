import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import BigNumber from 'bignumber.js';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useEthers, useContractFunction } from '@usedapp/core'
import { formatEther, formatUnits } from '@ethersproject/units'

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { useSnackbar } from 'notistack';

import { Link } from 'react-router-dom';

import useWindowSize from '../hooks/useWindowSize';
import useTokenTransmuterPROBalance from '../hooks/useTokenTransmuterPROBalance';
import useTokenTransmuterContractMetadata from '../hooks/useTokenTransmuterContractMetadata';
import useTokenTransmuterContract from '../hooks/useTokenTransmuterContract';
import useBurntSEENBalance from '../hooks/useBurntSEENBalance';

import TwoToneBlock from '../components/TwoToneBlock';
import EthereumInteractionZone from '../components/EthereumInteractionZone';
import CountdownTimer from '../components/CountdownTimer';
import { ExternalLink } from '../components/ExternalLink';

import { networkToTokenTransmuterContract } from '../utils/constants';

import AbstractCircles from '../assets/svg/abstract-circles.svg';
import SeenIcon from '../assets/svg/seen-icon.svg';

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: 275,
        marginBottom: 15
    },
    titleContent: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    miniTitle: {
        marginBottom: theme.spacing(1),
    },
    lightText: {
        color: '#838383',
    },
    subtitle: {
        fontWeight: 500,
    },
    optionContainer: {
        marginTop: theme.spacing(4)
    },
    distributionContainer: {
        marginTop: theme.spacing(4)
    },
    orCircle: {
        width: '50px',
        height: '50px',
        backgroundColor: '#130B27',
        borderRadius: '100%',
        position: 'absolute',
        left: 'calc(50% + 16px)',
        top: 'calc(50% + 16px)',
        transform: 'translateX(-50%)translateY(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 6px 20px 0px #8e98a063',
    },
    optionStats: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    abstractCircles: {
        position: 'fixed',
        zIndex: -1,
    },
    abstractCirclesPosition1: {
        left: 250
    },
    abstractCirclesPosition2: {
        right: 0,
        top: '50%'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    }
  }),
);

const HomePage = () => {
    const classes = useStyles();
    const { account, chainId } = useEthers();

    const windowSize = useWindowSize();

    const { enqueueSnackbar } = useSnackbar();

    const [isMobile, setIsMobile] = useState(false);
    const [isEntryClosed, setIsEntryClosed] = useState(false);

    const {readSuccess: proReadSuccess, balance: proBalance} = useTokenTransmuterPROBalance();
    const {
        readSuccess: vesterMetadataReadSuccess,
        totalAllocatedOutputToken,
        totalReleasedOutputToken,
    } = useTokenTransmuterContractMetadata();
    const {
      readSuccess: burntBalanceReadSuccess,
      burntBalance,
    } = useBurntSEENBalance();

    useLayoutEffect(() => {
        let sizeConsiderMobile = 1200;
        if (windowSize.width && (windowSize.width <= sizeConsiderMobile)) {
            setIsMobile(true);
        }else{
            setIsMobile(false);
        }
    }, [windowSize.width, windowSize.height, setIsMobile])

    return (
        <Container maxWidth="lg" style={{maxWidth: 1000, marginBottom: 65, marginTop: 24}}>
            <img className={[classes.abstractCircles, classes.abstractCirclesPosition1].join(' ')} alt="abstract ripple circles" src={AbstractCircles} />
            <img className={[classes.abstractCircles, classes.abstractCirclesPosition2].join(' ')} alt="abstract ripple circles" src={AbstractCircles} />
            <Grid container style={{position: 'relative'}} spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                  <div>
                      <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Allocated PRO</Typography>
                      {<Typography variant="h5" style={{fontWeight: 'bold'}}>{totalAllocatedOutputToken ? new BigNumber(formatUnits(new BigNumber(totalAllocatedOutputToken).toString(), 8)).decimalPlaces(2).toFormat(2) : 0} PRO</Typography>}
                  </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                  <div>
                      <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Unallocated PRO</Typography>
                      {<Typography variant="h5" style={{fontWeight: 'bold'}}>{(totalAllocatedOutputToken && proBalance && totalReleasedOutputToken) ? new BigNumber(formatUnits(new BigNumber(proBalance).minus(new BigNumber(totalAllocatedOutputToken).minus(new BigNumber(totalReleasedOutputToken))).toString(), 8)).decimalPlaces(2).toFormat(2) : 0} PRO</Typography>}
                  </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                  <div>
                      <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Released PRO</Typography>
                      {<Typography variant="h5" style={{fontWeight: 'bold'}}>{(totalReleasedOutputToken) ? new BigNumber(formatUnits(new BigNumber(totalReleasedOutputToken).toString(), 8)).decimalPlaces(2).toFormat(2) : 0} PRO</Typography>}
                  </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                  <div>
                      <Typography variant="overline" className={[classes.lightText, 'stat-bundle-text'].join(' ')} style={{fontWeight: 'bold'}}>Burnt SEEN</Typography>
                      {<Typography variant="h5" style={{fontWeight: 'bold'}}>{burntBalance ? new BigNumber(formatUnits(new BigNumber(burntBalance).toString(), 18)).decimalPlaces(2).toFormat(2) : 0} SEEN Burnt</Typography>}
                  </div>
              </Grid>
            </Grid>
        </Container>
    )
};

export default HomePage;