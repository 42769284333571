import React, {useState, useEffect} from 'react';

import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      // boxShadow: '0px 6px 20px 0px #8e98a063',
    },
    lightToneContainer: {
      width: '100%',
      background: '#FFFFFF',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    darkToneOuterContainer: {
      width: '100%',
      background: '#f7f8fa',
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'auto',
    },
    darkToneInnerContainer: {
      minWidth: 405,
    }
  }),
);

type TwoToneBlockProps = {
  lightToneContent: React.ReactNode,
  darkToneContent: React.ReactNode,
  defaultOrientation: "row" | "column",
  forceOrientation?: "row" | "column",
  isMobile: Boolean,
  selectedBlock?: Boolean
}

const TwoToneBlock = (props: TwoToneBlockProps) => {
  const classes = useStyles();

  const { lightToneContent, darkToneContent, defaultOrientation, forceOrientation, isMobile, selectedBlock } = props;

  const [flexDirection, setFlexDirection] = useState(forceOrientation ? forceOrientation : defaultOrientation);

  useEffect(() => {
    if(forceOrientation) {
      setFlexDirection(forceOrientation)
    } else {
      if(isMobile) {
        setFlexDirection('column');
      } else {
        setFlexDirection(defaultOrientation);
      }
    }
  }, [defaultOrientation, forceOrientation, isMobile])
  
  return (
      <Card elevation={0} className={classes.root} style={{display: 'flex', flexDirection: flexDirection, border: selectedBlock ? '1px solid #34ca98' : '1px solid #dfe4e9'}}>
        <div className={classes.lightToneContainer} style={{...(flexDirection === 'column' ? {borderBottom: '1px solid rgb(223, 228, 233)'} : {borderRight: '1px solid rgb(223, 228, 233)'})}}>
          {lightToneContent}
        </div>
        <div className={classes.darkToneOuterContainer}>
          <div className={classes.darkToneInnerContainer}>
            {darkToneContent}
          </div>
        </div>
      </Card>
  )
}

export default TwoToneBlock