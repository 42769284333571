import React, { useEffect, useState } from 'react'
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core'
import styled from 'styled-components'
import Web3Modal from 'web3modal'

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { useSnackbar } from 'notistack';

import WalletConnectProvider from '@walletconnect/web3-provider'

import { supportedNetworkId } from '../utils/constants';
import IncorrectNetworkModal from './IncorrectNetworkModal';

interface IWeb3ModalButtonProps {
  children?: React.ReactNode;
  isMobile?: boolean;
}

export const Web3ModalButton = (props: IWeb3ModalButtonProps) => {
  const { children, isMobile } = props;
  const { account, activate, deactivate, chainId } = useEthers()
  // const ens = useLookupAddress()
  const { enqueueSnackbar } = useSnackbar();
  const [activateError, setActivateError] = useState('')
  const [showNetworkSwitchModal, setShowNetworkSwitchModal] = useState(false);
  const [provider, setProvider] = useState();

  const { error } = useEthers()

  useEffect(() => {
    if (error) {
      // Temp workaround to avoid network changed error message until useDapp handles this internally
      if(
        error?.message?.indexOf('underlying network changed') === -1
        && error?.message?.indexOf('code=CALL_EXCEPTION') === -1
        && error?.message?.indexOf('Not configured chain id') === -1
      ) {
        setActivateError(error.message)
      } else if (error?.message?.indexOf('code=CALL_EXCEPTION') > -1) {
        setActivateError('Please refresh')
      }
    }
  }, [error])

  useEffect(() => {
    if(chainId) {
      if(Number(chainId) !== supportedNetworkId) {
        setShowNetworkSwitchModal(true);
      } else if (Number(chainId) === supportedNetworkId) {
        setShowNetworkSwitchModal(false);
      }
    }
  }, [chainId])

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: 'Metamask',
          description: 'Connect with the provider in your Browser',
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: 'https://bridge.walletconnect.org',
          rpc: {
            1: `${process.env.REACT_APP_ALCHEMY_RPC_URL}`,
          },
        },
      },
    }

    const web3Modal = new Web3Modal({
      providerOptions,
    })

    try {
      const useProvider = await web3Modal.connect()
      setProvider(useProvider);
      if(useProvider?.chainId && Number(useProvider?.chainId) === supportedNetworkId) {
        await activate(useProvider)
      } else {
        enqueueSnackbar("Unsupported network, please switch your wallet provider to Ethereum mainnet and reconnect.", { variant: 'error'})
      }
      setActivateError('')
    } catch(error: any) {
      setActivateError(error?.message)
    }
  }

  if(!children) {
    return (
      <>
        <Account>
          {!isMobile && <ErrorWrapper>{activateError}</ErrorWrapper>}
          {account ? (
            <>
              <AccountChip style={{backgroundColor: 'white', border: '1px solid #25c386', color: 'black', height: '38px', borderRadius: '4px', marginRight: '-6px', paddingRight: '5px', ...(isMobile && {width: '100%'})}} label={shortenAddress(account)}/>
              <Button variant="outlined" style={{border: 'none', ...(isMobile && {width: '100%'})}} color="inherit" className={'gradient-button'} onClick={() => deactivate()}>Disconnect</Button>
            </>
          ) : (
            <Button variant="outlined" style={{border: 'none', ...(isMobile && {width: '100%'})}} color="inherit" className={'gradient-button'} onClick={activateProvider}>Connect</Button>
          )}
        </Account>
        <IncorrectNetworkModal provider={provider} showModal={showNetworkSwitchModal}/>
      </>
    )
  } else {
    return (
      account 
      ?
        <CustomButton onClick={() => deactivate()}>
          {children}
        </CustomButton>
      : 
        <CustomButton onClick={activateProvider}>
          {children}
        </CustomButton>
    )
  }
}

const ErrorWrapper = styled.div`
  color: #ff3960;
  margin-right: 40px;
  margin-left: 40px;
  overflow: auto;
`

const Account = styled.div`
  display: flex;
  align-items: center;
`

const AccountChip = styled(Chip)`
  margin-right: 15px;
  color: white;
`

const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`